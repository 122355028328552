import { ChangeEvent, FormEvent, KeyboardEvent as ReactKeyboardEvent, useEffect, useRef, FocusEvent, ReactNode } from "react";
import { FormControl, Input, InputLeftElement, InputRightElement, Box, IconButton, BoxProps, InputGroup } from "@chakra-ui/react";
import { useOutsideClick } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { IconClose, IconSearch } from "src/components/icons";

const CLEAR_BUTTON_TEST_ID = "clear-button";

interface ITypeaheadUI {
    /** The current query entered in the search input */
    query?: string;

    /** Ghost text to show while nothing is entered in the search box */
    placeholderText?: string;

    /** Indication of loading state */
    isLoading?: boolean;

    /**  Enables autofocus on search input on first render, is disabled by default */
    enableAutoFocus?: boolean;

    /** Callback for when the search input changes */
    onChange: (queryValue: string) => unknown;

    /** Callback pressing clear button  */
    onClear: () => void;

    /** Callback for when the typeahead gains keyboard focus */
    onFocus?: (event: FormEvent<EventTarget>) => unknown;

    /** Callback for when the typeahead is manually dismissed */
    onClose?: () => unknown;

    /** Callback for when a key is pressed */
    onKeyDown?: (event: ReactKeyboardEvent<EventTarget>) => unknown;

    /** Callback for when the search query is submitted */
    onSubmit?: (event: FormEvent<EventTarget>) => unknown;

    /**  Search results will come as children */
    children: ReactNode;

    boxProps?: BoxProps;
}

export const TypeaheadUI = ({
    query,
    placeholderText,
    isLoading,
    enableAutoFocus = false,
    onChange,
    onClear,
    onFocus,
    onClose,
    onKeyDown,
    onSubmit,
    children,
    ...boxProps
}: ITypeaheadUI) => {
    const { t } = useTranslation("common");
    const containerRef = useRef<HTMLInputElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (enableAutoFocus) {
            inputRef.current?.focus();
        }
    }, [enableAutoFocus]);

    useOutsideClick({
        ref: containerRef,
        handler: onClose,
    });

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => onChange(event.currentTarget.value);

    const handleOnClear = () => {
        inputRef.current?.focus();
        onClear();
    };

    return (
        <Box ref={containerRef} position="relative" {...boxProps}>
            <FormControl
                as="form"
                aria-busy={isLoading ? "true" : undefined}
                onSubmit={onSubmit}
                onFocus={onFocus}
                onInvalid={evt => evt.preventDefault()}
            >
                <InputGroup>
                    <InputLeftElement pointerEvents="none" h="100%" w={7}>
                        <IconSearch boxSize={3} minW={7} />
                    </InputLeftElement>
                    <Input
                        textOverflow="ellipsis"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        required
                        title={t("search")}
                        aria-label={t("search")}
                        backgroundColor="white"
                        placeholder={placeholderText}
                        pl={7}
                        pr={7}
                        ref={inputRef}
                        value={query}
                        onChange={handleOnChange}
                        onKeyDown={onKeyDown}
                    />

                    {Boolean(query?.length) && (
                        <InputRightElement h="100%" right={1}>
                            <IconButton
                                data-test-id={CLEAR_BUTTON_TEST_ID}
                                aria-label={t("clear")}
                                onClick={handleOnClear}
                                onFocus={(event: FocusEvent) => {
                                    event.stopPropagation();
                                }}
                                variant="ghost"
                                rounded="50%"
                                boxSize={5}
                                minW={5}
                                p={2}
                                icon={<IconClose boxSize={3} />}
                            ></IconButton>
                        </InputRightElement>
                    )}
                </InputGroup>
            </FormControl>
            {children}
        </Box>
    );
};
