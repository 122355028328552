import { ReactNode } from "react";
import { BoxProps } from "@chakra-ui/react";
import { ISearchUnderlying, ISearchWikifolio } from "src/api/client/search-autocomplete.api";
import { useTypeaheadEventHandlers } from "./hooks/use-typeahead-event-handlers";
import { ITypeaheadState, useTypeaheadState } from "./hooks/use-typeahead-state";
import { TypeaheadUI } from "./typeahead-ui";
import { IPlacement } from "./typeahead.types";

export type ITypeaheadWrapper = Pick<
    ITypeahead<ISearchWikifolio | ISearchUnderlying>,
    "placeholderText" | "enableAutoFocus" | "onFocus" | "placement"
>;

export interface ITypeahead<TResultItem> extends Pick<BoxProps, "width"> {
    placeholderText: string;
    highlightFirstResult?: boolean;
    enableAutoFocus?: boolean;
    placement?: IPlacement;
    combinedResults: ReadonlyArray<TResultItem>;
    query: string;
    setQuery: (query: string) => void;
    onClear: () => void;
    onFocus?: () => void;
    onSelect: (selectedItem: TResultItem, selectedIndex: number) => void;
    onSubmit?: () => void;
    children: (state: ITypeaheadState) => ReactNode;
}

export const Typeahead = <TResultItem,>({
    placeholderText,
    enableAutoFocus,
    combinedResults,
    query,
    setQuery,
    onClear,
    onFocus,
    onSelect,
    onSubmit,
    children,
    ...boxProps
}: ITypeahead<TResultItem>) => {
    const state = useTypeaheadState();
    const { handleSearchChange, handleFocus, handleClear, handleClose, handleKeyDown, handleSubmit } = useTypeaheadEventHandlers({
        combinedResults,
        state,
        events: { setQuery, onClear, onFocus, onSelect, onSubmit },
    });

    return (
        <TypeaheadUI
            onChange={handleSearchChange}
            enableAutoFocus={enableAutoFocus}
            onFocus={handleFocus}
            query={query}
            placeholderText={placeholderText}
            onClear={handleClear}
            onClose={handleClose}
            onKeyDown={handleKeyDown}
            onSubmit={handleSubmit}
            {...boxProps}
        >
            {children(state)}
        </TypeaheadUI>
    );
};
