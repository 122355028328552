import Input from "./input";

const parts = ["field", "icon"];

const baseStyleField = {
    width: "100%",
    minWidth: 0,
    outline: 0,
    position: "relative",
    appearance: "none",
    transition: "all 0.2s",
    paddingBottom: "1px",
    paddingEnd: "48px",
    lineHeight: "normal",
    cursor: "pointer",
    "> option, > optgroup": {
        bg: "white",
    },
};

const baseStyleIcon = {
    width: "1.5rem",
    height: "100%",
    insetEnd: "0.5rem",
    position: "relative",
    color: "currentColor",
    fontSize: "1.5rem",
};

const baseStyle = {
    field: baseStyleField,
    icon: baseStyleIcon,
};

const compactVariant = {
    field: {
        padding: 1,
        paddingRight: 2,
    },
    icon: {
        width: 2,
        right: 0,
    },
};

const defaultProps = {
    size: "md",
    variant: "outline",
};

export default {
    parts,
    baseStyle,
    sizes: Input.sizes,
    variants: {
        outline: Input.variants.outline,
        compact: compactVariant,
    },
    defaultProps,
};
