const variants = {
    monoGray: {
        track: { background: "gray.800", _focusVisible: { boxShadow: "none" } },
    },
};

const sizes = {
    md: {
        track: { width: "40px", height: "20px" },
        thumb: { width: "20px", height: "20px", "--switch-thumb-x": "20px" },
    },
    lg: {
        track: { width: "56px", height: "28px" },
        thumb: { width: "28px", height: "28px", "--switch-thumb-x": "28px" },
    },
};

const baseStyle = {
    track: {
        padding: "2px",
        _focusVisible: { boxShadow: ["none", "0 0 0 2px #333333"] },
        _focus: {
            boxShadow: "none",
        },
    },
};

export default {
    baseStyle,
    sizes,
    variants,
};
