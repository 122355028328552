import { AvatarProps, Avatar, Image } from "@chakra-ui/react";

interface IUnderlyingLogoProps extends AvatarProps {
    isin: string | undefined;
}

const BASE_UNDERLYING_LOGO_URL = `${process?.env?.NEXT_PUBLIC_IMGIX_URL}/${process?.env?.NEXT_PUBLIC_CLOUD_BLOB_ENVIRONMENT_SHORTNAME}/images/underlying-logos`;
const UNDERLYING_FALLBACK_LOGO_URL = `${BASE_UNDERLYING_LOGO_URL}/000000000000.png`;

const UnderlyingLogo = ({ isin, ...props }: IUnderlyingLogoProps) => (
    <Avatar
        icon={<Image src={UNDERLYING_FALLBACK_LOGO_URL} />}
        variant="grayOutline"
        size="sm"
        src={`${BASE_UNDERLYING_LOGO_URL}/${isin?.toUpperCase()}.png`}
        {...props}
    />
);

export { UnderlyingLogo };
