import {
    forwardRef,
    // eslint-disable-next-line no-restricted-imports
    Select,
    SelectProps,
} from "@chakra-ui/react";
import { IconCaret } from "src/components/icons";

export const WfSelect = forwardRef<SelectProps, "select">(({ children, variant, ...selectProps }, ref) => {
    return (
        <Select
            ref={ref}
            icon={<IconCaret />}
            variant={variant}
            {...selectProps}
            sx={{
                "&": {
                    color: selectProps.value === "" ? "gray.500" : "current",
                    cursor: selectProps.isDisabled ? "not-allowed" : "pointer",
                    pr: variant === "compact" ? 2 : 6,
                },
                // arrow icon
                "& + div": {
                    color: selectProps.isDisabled ? "gray.500" : "current",
                    right: variant === "compact" ? 0 : 2,
                },
                "& option[value='']": { color: "gray.500" }, // placeholder *options* should be subtle (firefox)
                "& :not(option[value=''])": { color: "gray.800" }, // all other *options* should be default text color
            }}
        >
            {children}
        </Select>
    );
});
